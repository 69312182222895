<template>
  <section>
    <Toast />
    <div class="bg-white my-2 rounded-md p-4">
      <div class="w-full flex items-center justify-between mb-8">
        <div class="flex gap-2 items-center">
          <i class="pi pi-cog"></i>
          <p class="font-bold text-xl">Configuración de mipres para creación de OV</p>
        </div>
        <Button @click="$router.push({ name: 'pharmasan.ventas.configuracion.config-mipres.cargue-archivos' })" label="Cargar agrupaciones" severity="warning" style="height: fit-content" />
      </div>
      <div class="flex gap-4">
        <div class="w-6/12">
          <div class="flex gap-2 items-center mb-4">
            <i class="pi pi-list"></i>
            <p class="font-medium text-lg">Configuración general para usuarios que creen OV MIPRES</p>
          </div>
          <div class="w-full grid grid-cols-1 gap-2">
            <div class="flex px-4 items-center">
              <div class="w-9/12">
                <p>Restricción creación de ov MIPRES de cantidad de dias previos:</p>
              </div>
              <div class="3/12">
                <InputNumber inputClass="w-16" :min="0" v-model="configSapStore.dias_minimos_entrega_mipres_general" inputId="integeronly" />
              </div>
            </div>
            <div class="flex px-4 items-center">
              <div class="w-9/12">
                <p>Restricción creación de ov MIPRES de cantidad de dias posteriores:</p>
              </div>
              <div class="w-3/12">
                <InputNumber inputClass="w-16" :min="0" v-model="configSapStore.dias_maximo_entrega_mipres_general" inputId="integeronly" />
              </div>
            </div>
          </div>
          <div class="w-full flex m-4">
            <Button @click="actualizarConfiguracionMipresGeneral" icon="pi pi-save" label="Guardar configuración general" />
          </div>
        </div>
        <Divider layout="vertical" />
        <div class="w-6/12">
          <div class="w-full flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <p class="font-medium text-lg">Asignación permiso temporal a usuarios para creación de ov con fechas fuera de rango</p>
          </div>
          <div class="w-full gap-2">
            <div class="w-full">
              <label for="usuario" class="text-sm text-gray-600">Usuario</label>
              <AutoComplete :disabled="validacionConfiguracionActiva()" @item-select="setUsuario" id="usuario" forceSelection inputClass="w-full" class="w-full" v-model="usuarioSeleccionado" :suggestions="usuariosBuscados" @complete="buscarUsuarios" field="fullName" />
            </div>
            <div class="w-full flex gap-4">
              <div class="w-4/12" v-if="!validacionConfiguracionActiva()">
                <label for="cant_dias_previos" class="text-sm text-gray-600">Cant.dias previos</label>
                <InputNumber :disabled="validacionConfiguracionActiva()" id="cant_dias_previos" :min="configSapStore.dias_minimos_entrega_mipres_general" class="w-full" v-model="permisoTemporal.cantidad_dias" inputId="integeronly" />
                <span class="text-xs italic text-gray-600 flex gap-2">La cantidad minima debe ser la general: <p class="text-green-600">{{ configSapStore.dias_minimos_entrega_mipres_general }}</p></span>
              </div>
              <div class="w-4/12" v-if="!validacionConfiguracionActiva()">
                <label for="fecha_inicio" class="text-sm text-gray-600">Fecha inicio permiso</label>
                <input :disabled="validacionConfiguracionActiva()" id="fecha_inicio" :class="validacionConfiguracionActiva() ? 'text-gray-500' : ''" class="p-2 rounded-md border border-gray-400 pb-1 w-full" :min="dayjs().format('YYYY-MM-DD HH:mm')" v-model="permisoTemporal.fecha_inicio" type="datetime-local">
              </div>
              <div class="w-4/12" v-if="!validacionConfiguracionActiva()">
                <label for="fecha_fin" class="text-sm text-gray-600">Fecha fin permiso</label>
                <input :disabled="validacionConfiguracionActiva()" id="fecha_fin" :class="validacionConfiguracionActiva() ? 'text-gray-500' : ''" class="p-2 rounded-md border border-gray-400 pb-1 w-full" :min="dayjs(permisoTemporal.fecha_inicio).format('YYYY-MM-DD HH:mm')"  v-model="permisoTemporal.fecha_fin" type="datetime-local">
              </div>
            </div>
          </div>
          <div class="w-full" v-if="validacionConfiguracionActiva()">
            <p class="font-medium my-4 text-lg text-green-600">El usuario seleccionado ya cuenta con una configuración temporal activa</p>
            <div class="flex gap-2 items-center">
              <div>
                <label class="text-xs text-gray-600">Cantidad de dias previos: </label>
                <InputNumber id="cant_dias_previos" :min="configSapStore.dias_minimos_entrega_mipres_general" v-model="permisoTemporal.cantidad_dias" inputId="integeronly" />
              </div>
              <div>
                <label class="text-xs text-gray-600">Fecha inicio: </label>
                <input id="fecha_inicio" class="p-2 rounded-md border border-gray-400 pb-1" :min="dayjs().format('YYYY-MM-DD HH:mm')" v-model="permisoTemporal.fecha_inicio" type="datetime-local">
              </div>
              <div>
                <label class="text-xs text-gray-600">Fecha fin: </label>
                <input id="fecha_fin" class="p-2 rounded-md border border-gray-400 pb-1" :min="dayjs(permisoTemporal.fecha_inicio).format('YYYY-MM-DD HH:mm')"  v-model="permisoTemporal.fecha_fin" type="datetime-local">
              </div>
            </div>
          </div>
          <div class="w-full flex mt-2 gap-2">
            <Button v-if="validacionConfiguracionActiva()" @click="actualizarRegistro" icon="pi pi-save" label="Actualizar permiso temporal" />
            <Button v-else @click="crearRegistro" icon="pi pi-save" label="Guardar permiso temporal" />
            <Button @click="limpiarData()" v-if="validacionConfiguracionActiva()" icon="pi pi-times" severity="danger" label="Cancelar" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue'
  import { useConfigSap } from '../../../../../stores/configuracion-campos-ov.store'
  import UsuariosService from '../../../../../services/usuarios.service'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import dayjs from 'dayjs'
  export default {
    name: 'configuracionMipresDias',
    setup () {
      // Services
      const _UsuariosService = ref(new UsuariosService())
      const toast = useToast()
      // Stores
      const configSapStore = useConfigSap()
      // References
      const usuariosBuscados = ref([])
      const usuarioSeleccionado = ref()
      const permisoTemporal = ref({
        usuario: 0,
        fecha_inicio: '',
        fecha_fin: '',
        cantidad_dias: configSapStore.dias_minimos_entrega_mipres_general,
        permiso: 'dias_previos'
      })
      // Methods
      const setUsuario = () => {
        if (typeof usuarioSeleccionado.value === 'object' && usuarioSeleccionado.value.id) {
          permisoTemporal.value.usuario = usuarioSeleccionado.value.id
          configSapStore.findConfigUsuario(usuarioSeleccionado.value.id).then((data) => {
            if (data) {
              if (dayjs().isBefore(data.fecha_fin)) {
                permisoTemporal.value.fecha_inicio = dayjs(data.fecha_inicio).format('YYYY-MM-DD HH:mm')
                permisoTemporal.value.fecha_fin = dayjs(data.fecha_fin).format('YYYY-MM-DD HH:mm')
                permisoTemporal.value.cantidad_dias = data.cantidad_dias
              }
            }
          })
        }
      }
      const validacionConfiguracionActiva = () => {
        if (configSapStore._configuracion_encontrada_usuario.fecha_fin && typeof usuarioSeleccionado.value === 'object' && usuarioSeleccionado.value.id) {
          return dayjs().isBefore(configSapStore._configuracion_encontrada_usuario.fecha_fin)
        } else {
          return false
        }
      }
      const buscarUsuarios = (event) => {
        _UsuariosService.value.buscar({ fullName: event.query, limit: 10 }).then(({ data }) => {
          usuariosBuscados.value = data
        })
      }
      const crearRegistro = () => {
        for (const [key, value] of Object.entries(permisoTemporal.value)) {
          if (!value) return toast.add({ severity: 'error', summary: 'Error', detail: `El campo ${key} es requerido`, life: 3150 })
        }
        if (configSapStore._configuracion_encontrada_usuario.usuario === permisoTemporal.value.usuario) {
          actualizarRegistro()
        } else {
          configSapStore.crearConfigSapMipresUsuario(permisoTemporal.value).then(() => {
            Swal.fire(
              'Creado',
              'Configuración creada con éxito',
              'success'
            ).then(() => {
              limpiarData()
            })
          })
        }
      }
      const actualizarRegistro = () => {
        for (const [key, value] of Object.entries(permisoTemporal.value)) {
          if (!value) return toast.add({ severity: 'error', summary: 'Error', detail: `El campo ${key} es requerido`, life: 3150 })
        }
        configSapStore.updateConfigUsuario(configSapStore._configuracion_encontrada_usuario.id, permisoTemporal.value).then((data) => {
          if (data) {
            Swal.fire(
              'Actualizado',
              `Configuración ${configSapStore._configuracion_encontrada_usuario.id} actualizada con éxito`,
              'success'
            ).then(() => {
              limpiarData()
            })
          }
        })
      }
      const actualizarConfiguracionMipresGeneral = () => {
        configSapStore.updataGeneralConfig({
          difference_of_minium_days_to_create_mipres_ov: configSapStore._dias_minimos_entrega_mipres_general,
          difference_of_maximum_days_to_generate_OV_when_it_is_mipres: configSapStore._dias_maximo_entrega_mipres_general
        }).then(() => {
          Swal.fire(
            'Actualizado',
            'Configuración general actualizada con éxito',
            'success'
          ).then(() => {
            limpiarData()
          })
        })
      }
      const limpiarData = () => {
        configSapStore.configuracion_encontrada_usuario = {
          id: 0,
          permiso: '',
          usuario: 0,
          cantidad_dias: 0,
          fecha_inicio: '',
          fecha_fin: ''
        }
        usuarioSeleccionado.value = ''
        permisoTemporal.value = {
          usuario: 0,
          fecha_inicio: '',
          fecha_fin: '',
          cantidad_dias: configSapStore.dias_minimos_entrega_mipres_general,
          permiso: 'dias_previos'
        }
      }
      onMounted(() => {
        configSapStore.obtenerListadoConfiguraciones()
      })
      return {
        configSapStore,
        permisoTemporal,
        usuariosBuscados,
        buscarUsuarios,
        setUsuario,
        usuarioSeleccionado,
        crearRegistro,
        dayjs,
        validacionConfiguracionActiva,
        actualizarRegistro,
        actualizarConfiguracionMipresGeneral,
        limpiarData
      }
    }
  }
</script>
<style scoped>
</style>
