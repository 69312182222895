import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class UsuariosService {
  buscar (params) {
    return http.get(`${baseUrl}/usuarios/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
